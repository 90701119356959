import Rails from "@rails/ujs"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import "channels"

//local time
require("local-time").start()


import 'core-js/stable'
import 'regenerator-runtime/runtime'

//import Turbolinks from "turbolinks"

//Turbolinks.start()


import "layouts"
import "children"
import "pricing"
import "cocoon"
import "uppy"
import "segment"
import "stripe"
import "researchsources"


Rails.start()



//require('children')



//chartkick
import "chartkick/chart.js"

// ***** START: ADDED BY KisoThemes *****
window.$ = window.jQuery = require("jquery")


// ***** END: ADDED BY KisoThemes *****

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//console.log('Hello World from Webpacker')

// ***** START: ADDED BY KisoThemes *****
require("bootstrap")
require("metismenu/dist/metisMenu")
require("jquery-slimscroll")
require('bootstrap-switch')
require('chartjs')
require('jquery-countdown')
require('jquery-countto')
require('cd-easypiechart')
require('fastclick')
require('object-fit-images')
require('flot/source/jquery.canvaswrapper');
require('flot/source/jquery.flot');
require('gmaps.core')
require('jasny-bootstrap')
require('jvectormap')
require('jquery-parallax.js')
require('code-prettify')
require('prismjs')
require('sparklines')
// ***** END: ADDED BY KisoThemes *****


global.$ = require('jquery')

require("kiso_themes")

//for the cocoon gem
require("@nathanvda/cocoon")

//flatpickr
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "m/d/Y",
  })
})
// end flatpickr




//image picker

require('image-picker/image-picker/image-picker.js')
require('image-picker/image-picker/image-picker.css')


   

//require("flatpickr/dist/flatpickr.css")


//abraham
require("packs/abraham.js");
//




