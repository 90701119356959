 //$(function() {
       //$("select").imagepicker();
       //  show_label  : true
       //});

//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {
      $("select").imagepicker({
        hide_select:  false, 
        show_label:   true,
        limit: $("#DomainPlanMax").val(),
        limit_reached: function(){alert('Domain limit reached -- upgrade subscription to select more domains.')},
          });
       });    
        //$("select").imagepicker({
        //  hide_select : true,
       //  show_label  : true
       // })





//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {        
        //alert("hi");
        //endDate: '+2d'
        
        var attr0 = $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date');
        var attr1 = $('#user_children_attributes_1_date_of_birth').attr('data-date-start-date');
        var attr2 = $('#user_children_attributes_2_date_of_birth').attr('data-date-start-date');
        var attr3 = $('#user_children_attributes_3_date_of_birth').attr('data-date-start-date');
        var attr4 = $('#user_children_attributes_4_date_of_birth').attr('data-date-start-date');

        // For some browsers, `attr` is undefined; for others,
        // `attr` is false.  Check for both.
        if (typeof attr0 !== typeof undefined && attr0 !== false) {
           $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date','-' + $("#MaxChildAgeInMonths").val() + 'm');
           //alert ( $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date') );
        }
        if (typeof attr1 !== typeof undefined && attr1 !== false) {
           $('#user_children_attributes_1_date_of_birth').attr('data-date-start-date','-' + $("#MaxChildAgeInMonths").val() + 'm');
           //alert ( $('#user_children_attributes_1_date_of_birth').attr('data-date-start-date') );
        }
        if (typeof attr2 !== typeof undefined && attr2 !== false) {
           $('#user_children_attributes_2_date_of_birth').attr('data-date-start-date','-' + $("#MaxChildAgeInMonths").val() + 'm');
           //alert ( $('#user_children_attributes_2_date_of_birth').attr('data-date-start-date') );
        }
        if (typeof attr3 !== typeof undefined && attr3 !== false) {
           $('#user_children_attributes_3_date_of_birth').attr('data-date-start-date','-' + $("#MaxChildAgeInMonths").val() + 'm');
           //alert ( $('#user_children_attributes_3_date_of_birth').attr('data-date-start-date') );
        }
        if (typeof attr4 !== typeof undefined && attr4 !== false) {
           $('#user_children_attributes_4_date_of_birth').attr('data-date-start-date','-' + $("#MaxChildAgeInMonths").val() + 'm');
           //alert ( $('#user_children_attributes_4_date_of_birth').attr('data-date-start-date') );
        }


         
           
      });



