
//views/after_register/_payment and views/subscriptions/new and views/billing/index
//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {         
if (document.getElementById("payment-form")) {
  console.log('Hello stripe card element')
  //import "./stripe/index"






          var stripe = Stripe(process.env.STRIPE_PUBLIC);

          // Create an instance of Elements
          var elements = stripe.elements();

          // Custom styling can be passed to options when creating an Element.
          // (Note that this demo uses a wider set of styles than the guide below.)
          var style = {
            base: {
              color: '#32325d',
              lineHeight: '24px',
              fontFamily: 'Helvetica Neue',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4'
              }
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a'
            }
          };

          // Create an instance of the card Element
          var card = elements.create('card', {style: style});

          // Add an instance of the card Element into the `card-element` <div>
          card.mount('#card-element');

          // Handle real-time validation errors from the card Element.
          card.addEventListener('change', function(event) {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = '';
            }
          });

          // Handle form submission
          var form = document.getElementById('payment-form');
          //in select payment
          form.addEventListener('submit', function(event) {
            event.preventDefault();

            stripe.createToken(card).then(function(result) {
              if (result.error) {
                // Inform the user if there was an error
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
                console.log(result.error.message);
              } else {
                // Send the token to your server
                  //alert(JSON.stringify(result.token));
                  console.log(JSON.stringify(result.token));

            // Insert the token ID into the form so it gets submitted to the server

            var form = document.getElementById('payment-form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', result.token.id);
            form.appendChild(hiddenInput);
            //alert(result.token.id);
           // alert('submitting form');
            // Submit the form
            form.submit();

              }
            });
          });

    }
}
    );