//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {    
      $('#switch-sm').click(function() {
      
          // Display anuual pricing
          if($(this).is(':checked')) {
            //alert("Hello! This is annual!!");
            $("#starter").val("starter-annualized-2024");
            $("#gold").val("gold-annualized-2024");
            $("#platinum").val("platinum-annualized-2024");
            $('.switch').prop('checked', true);

            $('.labels span.monthly').removeClass('active')
            $('.labels span.yearly').addClass('active')

            $("p[data-pricing-for='monthly']").hide();
            $("p[data-pricing-for='annual']").show();
            $("#platinum-annualized-2024").attr('class', '');
            $("#platinum-monthly-2024").attr('class', 'd-none');
            $("#starter-monthly-2024").attr('class', 'd-none');
            $("#starter-annualized-2024").attr('class', '');
            $("#gold-monthly-2024").attr('class', 'd-none');
            $("#gold-annualized-2024").attr('class', '');
            $("#monthlylabel").attr('class', '');
            $("#yearlylabel").attr('class', 'font-weight-bold');
          } else {
           // alert("Hello! This is monthly");
            $("#starter").val("starter-monthly-2024");
            $("#gold").val("gold-monthly-2024");
            $("#platinum").val("platinum-monthly-2024");
            $('.switch').prop('checked', false);
            
            $('.labels span.yearly').removeClass('active')
            $('.labels span.monthly').addClass('active')

            $("p[data-pricing-for='annual']").hide();
            $("p[data-pricing-for='monthly']").show();
            $("#platinum-annualized-2024").attr('class', 'd-none');
            $("#platinum-monthly-2024").attr('class', '');
            $("#starter-monthly-2024").attr('class', '');
            $("#starter-annualized-2024").attr('class', 'd-none');
            $("#gold-monthly-2024").attr('class', '');
            $("#gold-annualized-2024").attr('class', 'd-none');
            $("#monthlylabel").attr('class', 'font-weight-bold');
            $("#yearlylabel").attr('class', '');
          }
      });
     });



