//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {
window.$crisp=[];window.CRISP_WEBSITE_ID="49d365b4-cec9-4b12-b9a2-c0781f3823b0";(function(){ d=document;s=d.createElement("script"); s.src="https://client.crisp.chat/l.js"; s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();

   });   




//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {      
    
         $("#user_children_attributes_0_date_of_birth").on("click", function(event) {
              
              $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date', 'Set by Derek');
               //alert ( $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date') );
              console.log($('#user_children_attributes_0_date_of_birth').attr('data-date-start-date'));
              console.log("link 0 clicked ");


          });
          
          $("#user_children_attributes_1_date_of_birth").on("click", function(event) {
              
              $('#user_children_attributes_1_date_of_birth').attr('data-date-start-date', 'Set by Derek');
               //alert ( $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date') );
              console.log($('#user_children_attributes_1_date_of_birth').attr('data-date-start-date'));
              console.log("link 1 clicked");


          });

            $("#this").on("click", function(event) {
              
              $('#this').attr('data-date-start-date', 'Set by Derek');
               //alert ( $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date') );
              console.log($('#this').attr('data-date-start-date'));
              console.log("link 3 + clicked");


          });


             $("#user_children_attributes_3_date_of_birth").on("click", function(event) {
              
              $('#user_children_attributes_3_date_of_birth').attr('data-date-start-date', 'Set by Derek');
               //alert ( $('#user_children_attributes_0_date_of_birth').attr('data-date-start-date') );
              console.log($('#user_children_attributes_3_date_of_birth').attr('data-date-start-date'));
              console.log("link 3 clicked");


          });
       
   });




//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {
        $('form').on('click', '.remove_record', function(event) {
          //alert("clicked");
          $(this).prev('input[type=hidden]').val(true);
          //$(this).prev("input[type=hidden]").attr("value","1");
          //alert("Here");
          $(this).closest('tr').hide();
          return event.preventDefault();
        });

        $('form').on('click', '.add_fields', function(event) {
          var regexp, time;
          time = new Date().getTime();
          regexp = new RegExp($(this).data('id'), 'g');
          $('.fields').append($(this).data('fields').replace(regexp, time));
          return event.preventDefault();
        });
        
      });



