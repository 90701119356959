//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {  
          $(function() {
           //alert($("#ChildPlanMax").val());
          // limits the number of categories
          
          $('#children').on('cocoon:after-insert', function() {

            check_to_hide_or_show_add_link();
            check_to_hide_or_show_remove_link();
          });

          $('#children').on('cocoon:after-remove', function() {
            check_to_hide_or_show_add_link();
            check_to_hide_or_show_remove_link();
          });

          check_to_hide_or_show_add_link();
          check_to_hide_or_show_remove_link();

          function check_to_hide_or_show_add_link() {
            if ($('#children .nested-fields').length == $("#ChildPlanMax").val()) {
              $('#children a.add_fields').hide();
              
            } else {
              $('#children a.add_fields').show();
            
            }

          
          }

           function check_to_hide_or_show_remove_link() {
            if ($('#children .nested-fields').length == 1) {
              $('#children a.remove_fields').hide();
              
            } else {
              $('#children a.remove_fields').show();
            
            }

          
          }

        })
      });


