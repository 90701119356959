import Shepherd from 'shepherd.js';
window.Shepherd = Shepherd;
import Cookies from 'js-cookie/src/js.cookie';
window.Cookies = Cookies;

let Abraham = new Object();
window.Abraham = Abraham;


Abraham.tours = {};
Abraham.incompleteTours = [];
Abraham.startTour = function(tourName) {
  if (!Shepherd.activeTour) {
  	Abraham.tours[tourName].start();
  }
};
Abraham.startNextIncompleteTour = function() {
  if (Abraham.incompleteTours.length) {
  	Abraham.tours[Abraham.incompleteTours[0]].checkAndStart();
  }
};

document.addEventListener("DOMContentLoaded", Abraham.startNextIncompleteTour);
//document.addEventListener("turbolinks:load", Abraham.startNextIncompleteTour); //removed 10/21/2022

//document.addEventListener('turbolinks:before-cache', function() { //removed 10/21/2022
$(document).ready(function () {
  // Remove visible product tours
  document.querySelectorAll(".shepherd-element").forEach(function(el) { el.remove() });
  // Clear Abraham data
  Abraham.tours = {};
  Abraham.incompleteTours = [];
});


//if (!Turbolinks) {
//    location.reload();
//}

//Turbolinks.dispatch("turbolinks:load");