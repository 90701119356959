//document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {          
  $('input[type=checkbox]').on('change', function(evt) {
    var max;
    max = $("#MaxNumberOfBooks").val();
    if ($(this).parent().parent().siblings().children().children(':checked').length + 1 > max) {
        this.checked = false;
       }
      });
});


